import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TwaAnalyticsProvider } from "@tonsolutions/telemetree-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <TwaAnalyticsProvider
      projectId={process.env.REACT_APP_TELEMETREE_PROJECT_ID!}
      apiKey={process.env.REACT_APP_TELEMETREE_API_KEY!}
      appName={"egreeProjectMiniApp"}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </TwaAnalyticsProvider>
  </React.StrictMode>
);
