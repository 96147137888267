import { useTWAEvent } from "@tonsolutions/telemetree-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../components/BackButton/BackButton";
import ButtonComponent, {
  ButtonBackgroundType,
  ButtonColorType,
} from "../../../../components/ButtonComponent/ButtonComponent";
import Toast from "../../../../components/Toast/Toast";
import serviceApi from "../../../../services/api";
import { ContractType } from "../../../../types";
import { EventNames, routes } from "../../../../types/enum";
import { ContractSteps } from "../../ContractCreation";
import {
  ButtonsWrapper,
  ContentWrapper,
  ShareBlock,
  ShareDescription,
  ShareTabDescription,
  ShareTabStyled,
  ShareTabTitle,
  ShareTitle,
} from "./ShareTab.styled";

type ShareTabProps = {
  setActiveTab: (tab: ContractSteps) => void;
  uuid: string;
  deal: ContractType | null;
};

export default function ShareTab({ setActiveTab, uuid, deal }: ShareTabProps) {
  const navigate = useNavigate();
  const eventBuilder = useTWAEvent();
  const [toastVisible, setToastVisible] = useState(false);
  const copyLink = `${process.env.REACT_APP_DIRECT_LINK}?startapp=${uuid}`;
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyLink);
      await serviceApi.events(EventNames.NDA_INVITATION_COPY_LINK_TG, {
        uid: deal?.uid,
        ndaId: deal?.id,
        uuid: deal?.uuid,
      });
      await eventBuilder.track(EventNames.NDA_INVITATION_COPY_LINK_TG, {
        uid: deal?.uid,
        ndaId: deal?.id,
        uuid: deal?.uuid,
      });
      setToastVisible(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleShare = async () => {
    await serviceApi.events(EventNames.NDA_INVITATION_SHARE_TG, {
      uid: deal?.uid,
      ndaId: deal?.id,
      uuid: deal?.uuid,
    });
    await eventBuilder.track(EventNames.NDA_INVITATION_SHARE_TG, {
      uid: deal?.uid,
      ndaId: deal?.id,
      uuid: deal?.uuid,
    });
  };

  const handleFinish = async () => {
    if (deal) {
      await serviceApi.events(EventNames.NDA_PROCEED_TG, { s_name: deal.content.type });
      await eventBuilder.track(EventNames.NDA_PROCEED_TG, { s_name: deal.content.type });

      navigate(routes.HOME);
    }
  };

  return (
    <ShareTabStyled>
      <ContentWrapper>
        <ShareTabTitle>Share with the counterparty</ShareTabTitle>
        <ShareTabDescription>
          You will be notified when your counterparty will sign the contract. So you can freely
          close this screen after sharing this contract
        </ShareTabDescription>

        <ShareBlock
          href={`https://t.me/share/url?url=${copyLink}`}
          target="_blank"
          onClick={handleShare}
        >
          <ShareTitle>Share</ShareTitle>
          <ShareDescription>Send a link to Telegram contact</ShareDescription>
        </ShareBlock>

        <ShareBlock onClick={handleCopy}>
          <ShareTitle>Copy link</ShareTitle>
          <ShareDescription>Copy and send link anywhere</ShareDescription>
        </ShareBlock>
      </ContentWrapper>

      <Toast
        duration={2000}
        onClose={() => setToastVisible(false)}
        message="Link Copied"
        visible={toastVisible}
        style={{ bottom: "100px" }}
      />

      <ButtonsWrapper>
        <BackButton onClick={() => setActiveTab("sign")} />
        <ButtonComponent
          backgroundType={ButtonBackgroundType.DarkBlue}
          colorType={ButtonColorType.White}
          onClick={handleFinish}
        >
          Finish
        </ButtonComponent>
      </ButtonsWrapper>
    </ShareTabStyled>
  );
}
