import { useMutation } from "@tanstack/react-query";
import { useTWAEvent } from "@tonsolutions/telemetree-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useTelegram } from "../../hooks/useTelegram";
import serviceApi from "../../services/api";
import { EventNames, routes } from "../../types/enum";
import authService from "../../services/auth";

export default function SignIn() {
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const eventBuilder = useTWAEvent();
  const { mutate, error: errorAuth } = useMutation({
    mutationFn: serviceApi.authTelegram,
    onSuccess: async (response: any) => {
      serviceApi.authorization(response.user.token);

      if (!!tg.initDataUnsafe.start_param) {
        try {
          const invitation = await serviceApi.dealInvitation(tg.initDataUnsafe.start_param);
          if (invitation.success) {
            const deal = await serviceApi.fetchDeal(tg.initDataUnsafe.start_param);
            await serviceApi.events(EventNames.EGREE_ACCEPTED_TG, {
              uid: deal?.uid,
              uuid: deal?.uuid,
            });
            await eventBuilder.track(EventNames.EGREE_ACCEPTED_TG, {
              uid: deal?.uid,
              uuid: deal?.uuid,
            });
            navigate(
              `${routes.DEALS}/${tg.initDataUnsafe.start_param}?userParamsId=${response.user.id}`
            );
          } else {
            navigate(routes.HOME);
          }
        } catch (error) {
          navigate(routes.HOME);
        }
      } else {
        navigate(routes.HOME);
      }
    },
    onError: (error) => {
      console.error("Error logging in:", error);
    },
  });

  useEffect(() => {
    tg.ready();
    if (tg && !!tg.initDataUnsafe.user?.id) {
      const token = authService.getAuthToken(tg.initDataUnsafe.user);
      mutate(token);
    }
  }, [tg, mutate]);

  if (errorAuth) {
    navigate(routes.AUTH_ERROR);
  }

  return (
    <>
      <Loader
        style={{
          height: "100vh",
          background: "linear-gradient(168.1deg, #f7eed6 -1.02%, #e3d2f1 100%)",
        }}
      />
    </>
  );
}
