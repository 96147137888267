import { useQuery } from "@tanstack/react-query";
import { useTWAEvent } from "@tonsolutions/telemetree-react";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import { v4 as uuidv4 } from "uuid";
import serviceApi from "../../services/api";
import { ContractType, UserType } from "../../types";
import { EventNames } from "../../types/enum";
import BeginTab from "./components/BeginTab/BeginTab";
import PersonalTab from "./components/PersonalTab/PersonalTab";
import PreviewTab from "./components/PreviewTab/PreviewTab";
import ShareTab from "./components/ShareTab/ShareTab";
import SignTab from "./components/SignTab/SignTab";
import StepsLayout from "./components/StepsLayout/StepsLayout";
import UploadTab from "./components/UploadTab/UploadTab";
import { ContractCreationStyled } from "./ContractCreation.styled";

export type ContractSteps = "begin" | "upload" | "preview" | "sign" | "share" | "personal";

export default function ContractCreation() {
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<ContractSteps>(
    (searchParams.get("step") as ContractSteps) || "begin"
  );
  const eventBuilder = useTWAEvent();
  const [file, setFile] = useState<File | null>(null);
  const signaturePadRef = useRef<SignaturePad>(null);
  const [uuid, setUuid] = useState<string>(searchParams.get("uuid") || uuidv4());
  const [deal, setDeal] = useState<ContractType | null>(null);
  const { data: user } = useQuery<UserType>({
    queryKey: ["user"],
    queryFn: () => serviceApi.getUser(),
  });
  const totalSegments = searchParams.get("type") === "solomon" ? 2 : 4;

  const initDeal = async () => {
    let template: any;
    const dealType = searchParams.get("type");
    switch (dealType) {
      case "solomon":
        template = await serviceApi.opinionAITemplate();
        break;
      case "custom":
      default:
        template = await serviceApi.customTemplate();
        break;
    }
    template.uuid = uuid;
    setDeal(template);
    await serviceApi.saveDeal(uuid, JSON.stringify(template));
  };

  async function fetchDeal(uuid: string) {
    try {
      const dealData = await serviceApi.fetchDeal(uuid);
      setDeal(dealData);
    } catch (error) {
      console.error(error);
    }
  }

  function activeTabRender() {
    switch (activeTab) {
      case "begin":
        return <BeginTab setActiveTab={setActiveTab} type={searchParams.get("type")} />;
      case "upload":
        return (
          <StepsLayout
            totalSegments={totalSegments}
            activeSegment={1}
            type={searchParams.get("type")}
          >
            <UploadTab
              setActiveTab={setActiveTab}
              setFile={setFile}
              file={file}
              uuid={uuid}
              initDeal={initDeal}
              setDeal={setDeal}
              user={user}
              deal={deal}
              type={searchParams.get("type")}
            />
          </StepsLayout>
        );
      case "preview":
        return (
          <StepsLayout
            totalSegments={totalSegments}
            activeSegment={2}
            type={searchParams.get("type")}
          >
            <PreviewTab setActiveTab={setActiveTab} uuid={uuid} type={searchParams.get("type")} />
          </StepsLayout>
        );
      case "sign":
        return (
          <StepsLayout
            totalSegments={totalSegments}
            activeSegment={3}
            type={searchParams.get("type")}
          >
            <SignTab
              setActiveTab={setActiveTab}
              signaturePadRef={signaturePadRef}
              uuid={uuid}
              deal={deal}
            />
          </StepsLayout>
        );
      case "share":
        return (
          <StepsLayout
            totalSegments={totalSegments}
            activeSegment={4}
            type={searchParams.get("type")}
          >
            <ShareTab setActiveTab={setActiveTab} uuid={uuid} deal={deal} />
          </StepsLayout>
        );
      case "personal":
        return <PersonalTab setActiveTab={setActiveTab} data={deal} />;
      default:
        return <BeginTab setActiveTab={setActiveTab} type={searchParams.get("type")} />;
    }
  }

  const handleEvent = async (step: ContractSteps) => {
    await serviceApi.events(`${EventNames.NDA_CREATION_CHANGE_STEP_TO_}${step.toUpperCase()}_TG`);
    await eventBuilder.track(`${EventNames.NDA_CREATION_CHANGE_STEP_TO_}${step.toUpperCase()}_TG`, {});
  };

  useEffect(() => {
    if (searchParams.get("uuid")) {
      fetchDeal(searchParams.get("uuid") as string);
    }
  }, []);

  useEffect(() => {
    try {
      handleEvent(activeTab);
    } catch (error) {
      console.log(error);
    }
  }, [activeTab]);

  return <ContractCreationStyled>{activeTabRender()}</ContractCreationStyled>;
}
