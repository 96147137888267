export enum routes {
  HOME = "/",
  SIGN_IN = "/sign-in",
  DEALS = "/deals",
  CONTRACT_CREATION = "/contract-creation",
  SETTINGS = "/settings",
  PROFILE = "/settings/profile",
  AUTH_ERROR = "/auth-error",
  NOT_FOUND = "/not-found",
}

export enum StatusNames {
  PENDING = "pending",
  EXPIRED = "expired",
  DRAFT = "draft",
  SIGNED = "signed",
  REJECTED = "rejected",
  CHECKED = "checked",
  DELETED = "deleted",
}

export enum EventNames {
  MAIN_CREATE_NDA_TG = "MAIN_CREATE_NDA_TG",
  MAIN_CREATE_SOLOMON_TG = "MAIN_CREATE_SOLOMON_TG",
  NDA_INVITATION_SHARE_TG = "NDA_INVITATION_SHARE_TG",
  NDA_INVITATION_COPY_LINK_TG = "NDA_INVITATION_COPY_LINK_TG",
  EGREE_ACCEPTED_TG = "EGREE_ACCEPTED_TG",
  PROFILE_FILLING_OUT_BEFORE_ACCEPT_TG = "PROFILE_FILLING_OUT_BEFORE_ACCEPT_TG",
  EGREE_SIGNED_TG = "EGREE_SIGNED_TG",
  EGREE_REJECTED_TG = "EGREE_REJECTED_TG",
  NDA_PROCEED_TG = "NDA_PROCEED_TG",
  NDA_CREATION_CHANGE_STEP_TO_ = "NDA_CREATION_CHANGE_STEP_TO_",
}
