/* eslint-disable react-hooks/rules-of-hooks */
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useTWAEvent } from "@tonsolutions/telemetree-react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Icon from "../../../../components/Icon/Icon";
import { useTelegram } from "../../../../hooks/useTelegram";
import serviceApi from "../../../../services/api";
import { ContractType, UserType } from "../../../../types";
import { EventNames, routes } from "../../../../types/enum";
import { DeepLinkSteps } from "../../DealById";
import { DealStatus } from "../DealStatus/DealStatus";
import ActionsComponent from "./ActionsComponent/ActionsComponent";
import Counterparty from "./Counterparty/Counterparty";
import {
  ButtonsWrapper,
  DealContent,
  DealHeader,
  DealPreviewTabStyled,
  DocumentPreview,
  People,
  Title,
} from "./DealPreviewTab.styled";

type DealPreviewTabProps = {
  setActiveTab: (tab: DeepLinkSteps) => void;
  deal?: ContractType;
  profile?: UserType;
};

type CreatorType = {
  id: number;
  name: string | null;
  secondName: string | null;
  status: number;
};

type Contact = {
  id: number;
  status: string | number;
  name: string | null;
  secondName: string | null;
};

interface PeopleData {
  signed: Contact[];
  pending: Contact[];
  rejected: Contact[];
  all: Contact[];
}

const DealPreviewTab = ({ setActiveTab, deal, profile }: DealPreviewTabProps) => {
  const pdfLink = `https://dev.e-gree.app/api/v2/nda/download/${deal?.uuid}?preview=formation`;
  const [searchParams] = useSearchParams();
  const { tg } = useTelegram();
  const eventBuilder = useTWAEvent();
  const navigate = useNavigate();

  const ownerSign = deal?.signatures.find((item) => item.userId === deal.owner);
  const creator: CreatorType = Object.assign(
    {},
    deal?.content.owner,
    {
      status: 1,
      id: deal?.owner,
    },
    ownerSign?.name && { name: ownerSign.name },
    ownerSign?.secondName && { secondName: ownerSign.secondName }
  );
  const aiopinion = deal?.content.type === "opiniongpt"; // is contract Solomon AI
  const self = deal?.contacts?.find((item) => item.id === profile?.id);
  const userId = searchParams.get("userParamsId");
  const filteredContacts = !userId
    ? deal?.contacts
    : deal?.contacts.filter((item) => item.id === Number(userId));
  const participants = [creator];
  const peoples: PeopleData = participants.concat(filteredContacts ? filteredContacts : []).reduce(
    (data, item) => {
      if (!item) return data;

      data.all.push(item);

      switch (Number(item.status)) {
        case 1:
          data.signed.push(item);
          break;
        case 2:
          data.rejected.push(item);
          break;
        default:
          data.pending.push(item);
          break;
      }

      return data;
    },
    { signed: [], pending: [], rejected: [], all: [] } as PeopleData
  );
  const data: Contact[] = peoples.all.map((item: any) => {
    const contact = Object.assign({}, item);

    switch (Number(contact.status)) {
      case 1:
        Object.assign(contact, {
          status: contact.id === deal?.owner ? "owner" : "signed",
        });
        break;

      case 2:
        Object.assign(contact, {
          status: "rejected",
        });
        break;

      default:
        Object.assign(contact, {
          resend: profile?.id === deal?.owner,
          status: "pending",
        });
        break;
    }

    return contact;
  });

  const handleClick = async (value: "accept" | "refuse") => {
    if (deal?.uuid) {
      switch (value) {
        case "accept":
          if (profile?.name && profile?.secondName) {
            setActiveTab("sign");
          } else {
            await serviceApi.events(EventNames.PROFILE_FILLING_OUT_BEFORE_ACCEPT_TG, {
              uid: deal.uid,
              uuid: deal.uuid,
            });
            await eventBuilder.track(EventNames.PROFILE_FILLING_OUT_BEFORE_ACCEPT_TG, {
              uid: deal.uid,
              uuid: deal.uuid,
            });
            setActiveTab("personal");
          }
          break;
        case "refuse":
          tg.showConfirm("Do you really want to refuse?", async (ok: boolean) => {
            if (ok) {
              try {
                await serviceApi.declineDeal(deal?.uuid);
                await serviceApi.events(EventNames.EGREE_REJECTED_TG, {
                  uid: deal.uid,
                  uuid: deal.uuid,
                });
                await eventBuilder.track(EventNames.EGREE_REJECTED_TG, {
                  uid: deal.uid,
                  uuid: deal.uuid,
                });
                navigate(routes.HOME);
              } catch (error) {
                console.log(error);
              }
            }
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <DealPreviewTabStyled>
      <DealHeader>
        <Title>{deal?.content.title}</Title>
        <People>
          {aiopinion ? (
            <Counterparty
              name={"Solomon"}
              family={"AI"}
              self={false}
              status={DealStatus.AICHECKED}
              avatar={() => (
                <Icon style={{ left: 0, right: 0, top: 0, bottom: 0 }} name="solomon" />
              )}
            />
          ) : (
            data.map((el) => (
              <Counterparty
                name={el.name as string}
                family={el.secondName as string}
                self={el.id === profile?.id}
                status={el.status as string}
              />
            ))
          )}
        </People>
      </DealHeader>

      <DealContent>
        <DocumentPreview>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfLink} defaultScale={SpecialZoomLevel.PageWidth} />
          </Worker>
        </DocumentPreview>

        <ButtonsWrapper>
          <ActionsComponent
            master={deal?.owner === profile?.id}
            decision={!!(self && !Number(self.status))}
            signed={!!deal?.signatures.find((item) => item.userId === profile?.id)}
            aiopinion={deal?.content.type === "opiniongpt"}
            setActiveTab={setActiveTab}
            handleClick={handleClick}
          />
        </ButtonsWrapper>
      </DealContent>
    </DealPreviewTabStyled>
  );
};

export default React.memo(DealPreviewTab);
